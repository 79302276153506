import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Home from './Home';
import Login from './Login';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useState, useEffect } from 'react';
import { sendwellDB, sendwellAuth } from './FirebaseInitSendwell';
import { mikeDB, mikeAuth } from './FirebaseInitMike';

function App() {
  const [authenticated, setAuthenticated] = useState(true);
  const [uid, setUid] = useState(localStorage.getItem('uid'));
  const [activeTab, setActiveTab] = useState(0);



  const testing = false;
  const sendwellOrMike = 'mike'; // 'sendwell' or 'mike'

  const productionServerURL = sendwellOrMike == 'sendwell' ? 'http://api.automail.sendwell.com' : 'https://roboapi.purbooks.com';
  const serverURL = testing ? 'http://localhost:4000' : productionServerURL;

  const roboURL = sendwellOrMike == 'sendwell' ? 'http://199.16.189.237/mwZycLJElPYC' : 'http://64.31.61.10/RNLeDUqAwJkC';
  const roboLoginData = sendwellOrMike == 'sendwell' ? {username: 'admin', password: 'ZIKKPQK5sy5U', submit: 'Login'} : {username: 'admin', password: 'Octoberfest1!', submit: 'Login'};





  const db = sendwellOrMike == 'sendwell' ? sendwellDB : mikeDB;
  const auth = sendwellOrMike == 'sendwell' ? sendwellAuth : mikeAuth;

  useEffect(() => {
    const URLPath = getLastPartOfURL();
    console.log(URLPath);
    if (URLPath === 'Stats') {
      setActiveTab(0);
    } else if (URLPath === 'GenerateLinks') {
      setActiveTab(1);
    } else if (URLPath === 'GenerateImage') {
      setActiveTab(2);
    } else if (URLPath === 'GenerateCreative') {
      setActiveTab(3);
    } else if (URLPath === 'Prompts') {
      setActiveTab(4);
    } else if (URLPath === 'Mailer') {
      setActiveTab(5);
    } else {
      setActiveTab(0); // Default to Home if path doesn't match
    }
  }, []);


  function getLastPartOfURL() {
    const pathArray = window.location.pathname.split('/');
    return pathArray.pop() || pathArray.pop(); // pop twice due to a trailing slash
  };

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        setAuthenticated(true);
        setUid(user.uid);
        localStorage.setItem('uid', user.uid);
      } else {
        setAuthenticated(false);
        console.log("No users are signed in");
      }
    });
  }, []);

  return ( 
    <BrowserRouter>     
      <Routes>
        <Route path="/Home" element={authenticated ? <Home uid={uid} activeTab={activeTab} setActiveTab={setActiveTab} serverURL={serverURL} sendwellOrMike={sendwellOrMike}/> :  <Navigate to="/"/>} />
        <Route path="/Stats" element={authenticated ? <Home uid={uid} activeTab={activeTab} setActiveTab={setActiveTab} serverURL={serverURL} sendwellOrMike={sendwellOrMike}/> :  <Navigate to="/"/>} />
        <Route path="/GenerateLinks" element={authenticated ? <Home uid={uid} activeTab={activeTab} setActiveTab={setActiveTab} serverURL={serverURL} sendwellOrMike={sendwellOrMike}/> :  <Navigate to="/"/>} />
        <Route path="/GenerateImage" element={authenticated ? <Home uid={uid} activeTab={activeTab} setActiveTab={setActiveTab} serverURL={serverURL} sendwellOrMike={sendwellOrMike}/> :  <Navigate to="/"/>} />
        <Route path="/GenerateCreative" element={authenticated ? <Home uid={uid} activeTab={activeTab} setActiveTab={setActiveTab} serverURL={serverURL} roboURL={roboURL} roboLoginData={roboLoginData} sendwellOrMike={sendwellOrMike}/> :  <Navigate to="/"/>} />
        <Route path="/Prompts" element={authenticated ? <Home uid={uid} activeTab={activeTab} setActiveTab={setActiveTab} serverURL={serverURL} roboURL={roboURL} roboLoginData={roboLoginData} sendwellOrMike={sendwellOrMike}/> :  <Navigate to="/"/>} />
        <Route path="/Mailer" element={authenticated ? <Home uid={uid} activeTab={activeTab} setActiveTab={setActiveTab} serverURL={serverURL} roboURL={roboURL} roboLoginData={roboLoginData} sendwellOrMike={sendwellOrMike}/> :  <Navigate to="/"/>} />
        <Route path="/" element={authenticated ? <Navigate to="/Home"/> : <Login uid={uid} sendwellOrMike={sendwellOrMike}/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
