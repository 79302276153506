import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import axios from 'axios';
import './Stats.css';
import { CalendarIcon, DateCalendar } from '@mui/x-date-pickers';
import { CalendarMonthOutlined } from '@mui/icons-material';
import ReactModal from 'react-modal';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { doc, onSnapshot, collection, query, where, getDocs, orderBy, deleteDoc, addDoc, updateDoc, getDoc, setDoc, limit, arrayUnion } from "firebase/firestore";
import { sendwellDB, sendwellAuth } from './FirebaseInitSendwell';
import { mikeDB, mikeAuth } from './FirebaseInitMike';


function Stats({ uid, serverURL, sendwellOrMike }) {

    const [selectedDateFilter, setSelectedDateFilter] = useState('thisMonth');
    const [dateStart, setDateStart] = useState('');
    const [dateEnd, setDateEnd] = useState('');
    const [userType, setUserType] = useState('');
    const [initials, setInitials] = useState('');
    const [mailings, setMailings] = useState(0);
    const [revenue, setRevenue] = useState(0);
    const [clicks, setClicks] = useState(0);
    const [delivered, setDelivered] = useState(0);
    const [deferrals, setDeferrals] = useState(0);
    const [rawDailyRevenueData, setRawDailyRevenueData] = useState([]);

    const [showDateSelectors, setShowDateSelectors] = useState(false);
    const [topOffers, setTopOffers] = useState([]);

    const db = sendwellOrMike == 'sendwell' ? sendwellDB : mikeDB;
    const auth = sendwellOrMike === 'sendwell' ? sendwellAuth : mikeAuth;

 
    // const serverURL = 'http://api.automail.sendwell.com'
    //const serverURL = 'https://stevenbrekalo.com:3000'
    //const serverURL = 'http://localhost:4000';


    async function getUserType () {
        const userRef = doc(db, "users", uid);
        const userSnap = await getDoc(userRef);
        setUserType(userSnap.data()?.type)
        setInitials(userSnap.data()?.initials)
    }
    useEffect(() => {
        getUserType()
    }, [])


    useEffect(() => {
        if (dayjs(dateStart).isValid() && dayjs(dateEnd).isValid() && userType !== '') {
            getDailyData();
        }
    }, [dateStart, dateEnd, userType]);

    async function getDailyData() {
        try {
            const revenueResponse = await axios.get(`${serverURL}/api/getDailyRevenue`, {
                params: {
                    dateStart: dateStart.format('YYYY-MM-DD'),
                    dateEnd: dateEnd.format('YYYY-MM-DD'),
                    ...(userType === 'franchise' ? { initials: initials } : {})
                }
            });
            const sendsResponse = await axios.get(`${serverURL}/api/getTotalSends`, {
                params: {
                    dateStart: dateStart.format('YYYY-MM-DD'),
                    dateEnd: dateEnd.format('YYYY-MM-DD'),
                    ...(userType === 'franchise' ? { initials: initials } : {})
                }
            });
            
            const topOffersResponse = await axios.get(`${serverURL}/api/getTopOffers`, {
                params: {
                    dateStart: dateStart.format('YYYY-MM-DD'),
                    dateEnd: dateEnd.format('YYYY-MM-DD'),
                    ...(userType === 'franchise' ? { initials: initials } : {})
                }
            });

            

            const revenueData = revenueResponse.data;
            const sendsData = sendsResponse.data;
            const sumRevenue = revenueData.reduce((acc, curr) => acc + curr.total_payout, 0);
            const sumClicks = revenueData.reduce((acc, curr) => acc + (curr.total_clicks * 1), 0);
            const sumMailings = sendsData.reduce((acc, curr) => acc + (curr.mailings * 1), 0);
            const sumDelivered = sendsData.reduce((acc, curr) => acc + (curr.success * 1), 0);
            const sumDeferrals = sendsData.reduce((acc, curr) => acc + (curr.deferrals * 1), 0);

            setRawDailyRevenueData(revenueData);
            setRevenue(sumRevenue);
            setClicks(sumClicks);

            setMailings(sumMailings);
            setDelivered(sumDelivered);
            setDeferrals(sumDeferrals);

            setTopOffers(topOffersResponse.data);
        } catch (error) {
            console.error(error);
        }
    }




    useEffect(() => {
        if (selectedDateFilter == 'today') {
            setDateStart(dayjs().startOf('day'));
            setDateEnd(dayjs().endOf('day'));
        } else if (selectedDateFilter == 'yesterday') {
            setDateStart(dayjs().subtract(1, 'day').startOf('day'));
            setDateEnd(dayjs().subtract(1, 'day').endOf('day'));
        } else if (selectedDateFilter == 'last7') {
            setDateStart(dayjs().subtract(7, 'day').startOf('day'));
            setDateEnd(dayjs().endOf('day'));
        } else if (selectedDateFilter == 'last30') {
            setDateStart(dayjs().subtract(30, 'day').startOf('day'));
            setDateEnd(dayjs().endOf('day'));
        } else if (selectedDateFilter == 'thisMonth') {
            setDateStart(dayjs().startOf('month'));
            setDateEnd(dayjs().endOf('month'));
        } else if (selectedDateFilter == 'thisQuarter') {
            const currentMonth = dayjs().month();
            const quarterStartMonth = Math.floor(currentMonth / 3) * 3; // Start of the quarter
            const quarterEndMonth = quarterStartMonth + 2; // End of the quarter
            const startDate = dayjs().month(quarterStartMonth).startOf('month');
            const endDate = dayjs().month(quarterEndMonth).endOf('month');

            setDateStart(startDate);
            setDateEnd(endDate);
        } else if (selectedDateFilter == 'thisYear') {
            setDateStart(dayjs().startOf('year'));
            setDateEnd(dayjs().endOf('year'));
        }
    }, [selectedDateFilter]);





    //CHART FUNCTIONS 
    const extractLabels = (data) => {
        return data.map(item => {
            const date = new Date(item.date);
            date.setDate(date.getDate() + 1); // Add one day to the date
            const month = date.toLocaleString('default', { month: 'short' });
            const day = date.getDate();
            return `${month} ${day}`;
        });
    };
    

    const extractPayouts = (data) => {
        return data.map(item => item.total_payout);
    };

    const labels = extractLabels(rawDailyRevenueData);
    const payouts = extractPayouts(rawDailyRevenueData);

    console.log(labels)
    console.log(payouts)

    const data = {
        labels: labels,
        datasets: [
            {
                label: 'Revenue',
                data: payouts,
                backgroundColor: 'rgb(43, 95, 226)',
                borderColor: 'rgb(43, 95, 226)',
                borderWidth: 1,
                borderRadius: 5,
            }
        ]
    };


    // Find the maximum value in the datasets
    const maxDataValue = Math.max(...data.datasets.flatMap(dataset => dataset.data));
    //const roundedMaxDataValue = Math.round(maxDataValue / 5000) * 5000;

    // Set the maximum value for the y-axis to be 1.67 times the highest data point
    const suggestedMax = maxDataValue * 1.67;

    const options = {
        plugins: {
            legend: {
                display: false, // Assuming you don't want to display a legend
            },
            title: {
                display: false, // Assuming no title is desired
            },
        },
        scales: {
            x: {
                grid: {
                    display: false, // Hides the grid lines for the X-axis
                    drawBorder: false, // Hides the axis border
                },
                ticks: {
                    color: 'black', // Set the color of the axis labels
                    maxTicksLimit: 15,
                    // Additional styling can be done here
                },
            },
            y: {
                beginAtZero: true, // Ensure the scale starts at 0
                max: suggestedMax, // Set the calculated maximum value
                grid: {
                    color: 'rgba(0, 0, 0, 0.1)', // Lightens the grid lines for the Y-axis
                    drawBorder: false, // Hides the axis border
                },
                ticks: {
                    color: 'black', // Set the color of the axis labels
                    maxTicksLimit: 10, // Set the maximum amount of vertical ticks
                    // Additional styling can be done here
                    callback: function(value, index, values) {
                        // This callback formats the Y-axis labels with a dollar sign
                        return '$' + value.toLocaleString();
                    },
                },
            },
        },
        responsive: true,
        maintainAspectRatio: false, // Set to false to control the chart's height independently
    };
    



    return (
        <div className='statsContainer'>
            <div className='statsContentContainer'>

                <div className='statsHeaderContainer'>
                    <div className='statsHeaderTopContainer'>
                        <span className={selectedDateFilter == 'today' ? 'statsHeaderTopFilterTextSelected' : 'statsHeaderTopFilterText'} onClick={() => {setSelectedDateFilter('today')}}>Today</span>
                        <span className={selectedDateFilter == 'yesterday' ? 'statsHeaderTopFilterTextSelected' : 'statsHeaderTopFilterText'} onClick={() => {setSelectedDateFilter('yesterday')}}>Yesterday</span>
                        <span className={selectedDateFilter == 'last7' ? 'statsHeaderTopFilterTextSelected' : 'statsHeaderTopFilterText'} onClick={() => {setSelectedDateFilter('last7')}}>Last 7 days</span>
                        <span className={selectedDateFilter == 'last30' ? 'statsHeaderTopFilterTextSelected' : 'statsHeaderTopFilterText'} onClick={() => {setSelectedDateFilter('last30')}}>Last 30 days</span>
                        <span className={selectedDateFilter == 'thisMonth' ? 'statsHeaderTopFilterTextSelected' : 'statsHeaderTopFilterText'} onClick={() => {setSelectedDateFilter('thisMonth')}}>This Month</span>
                        <span className={selectedDateFilter == 'thisQuarter' ? 'statsHeaderTopFilterTextSelected' : 'statsHeaderTopFilterText'} onClick={() => {setSelectedDateFilter('thisQuarter')}}>This Quarter</span>
                        <span className={selectedDateFilter == 'thisYear' ? 'statsHeaderTopFilterTextSelected' : 'statsHeaderTopFilterText'} onClick={() => {setSelectedDateFilter('thisYear')}}>This Year</span>
                    </div>
                    <div className='statsHeaderBottomContainer'>
                        <div className='statsHeaderStatContainer'>
                            <span className='statsHeaderStatValue'>{new Intl.NumberFormat('en-US').format(mailings)}</span>
                            <span className='statsHeaderStatTitle'>Mailings</span>
                        </div>
                        <div className='statsHeaderStatContainer'>
                            <span className='statsHeaderStatValue'>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(revenue)}</span>
                            <span className='statsHeaderStatTitle'>Earnings</span>
                        </div>
                        <div className='statsHeaderStatContainer'>
                            <span className='statsHeaderStatValue'>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(revenue / (delivered / 1000000))}</span>
                            <span className='statsHeaderStatTitle'>EPM</span>
                        </div>
                        <div className='statsHeaderStatContainer'>
                            <span className='statsHeaderStatValue'>{new Intl.NumberFormat('en-US').format(clicks)}</span>
                            <span className='statsHeaderStatTitle'>Clicks</span>
                        </div>
                        <div className='statsHeaderStatContainer'>
                            <span className='statsHeaderStatValue'>{new Intl.NumberFormat('en-US').format(delivered)}</span>
                            <span className='statsHeaderStatTitle'>Delivered</span>
                        </div>
                        <div className='statsHeaderStatContainer'>
                            <span className='statsHeaderStatValue'>{new Intl.NumberFormat('en-US').format(deferrals)}</span>
                            <span className='statsHeaderStatTitle'>Deferrals</span>
                        </div>
                        <div className='statsHeaderStatContainer'>
                            <span className='statsHeaderStatValue'>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(revenue / mailings)}</span>
                            <span className='statsHeaderStatTitle'>Average</span>
                        </div>
                    </div>
                    <div className='statsFloatingDateSelectorButton' onClick={() => {setShowDateSelectors(!showDateSelectors)}}>
                        <CalendarIcon />
                    </div>
                </div>



                <div className='statsContentRow'>

                    <div className='statsCard'>
                        <span className='statsCardTitle'>Earnings By Day</span>
                        <div className='statsChartContainer'>
                            <Bar data={data} options={options} />
                        </div>
                    </div>


                    <div className='statsCard'>
                        <span className='statsCardTitle'>Offers</span>
                        <div className='statsCardTableContainer'>
                            <table className='statsCardTable'>
                                <thead>
                                    <tr>
                                        <td>Name</td>
                                        <td>Sent</td>
                                        <td>Revenue</td>
                                        <td>EPM</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {topOffers.map((x, index) => { return (
                                        <tr key={index}>
                                            <td>{x.prompt_name}</td>
                                            <td>{new Intl.NumberFormat('en-US').format(x.total_sent)}</td>
                                            <td>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(x.total_payout)}</td>
                                            <td>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(x.epm)}</td>
                                        </tr>
                                    )})}
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>

{/* 
                <div className='statsContentRow'>

                    <div className='statsCard'>
                        <span className='statsCardTitle'>Lists</span>
                    </div>


                    <div className='statsCard'>
                        <span className='statsCardTitle'>Specialists</span>
                    </div>

                </div> */}

                <span>BETA PREVIEW</span>



            </div>








        <div className='statsDateSelectorBackground' style={{display: showDateSelectors ? 'block' : 'none'}}>
            <div className='statsDateSelectorCard'>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div className='statsDateSelectorsContainer'>
                    <DateCalendar value={dateStart} onChange={(newValue) => setDateStart(newValue)} />
                    <DateCalendar value={dateEnd} onChange={(newValue) => setDateEnd(newValue)} />
                    </div>
                </LocalizationProvider>
                <div className='statsDateSelectorSubmitButtonContainer'>
                    <span className='statsDateSelectorSubmitButton' onClick={() => {getDailyData(); setShowDateSelectors(false)}}> Query Dates </span>

                </div>

            </div>
        </div>


        </div>
    );
}

export default Stats;
