import React, { useState, useEffect } from 'react';
import './Home.css';
import Links from './Links';
import Stats from './Stats';
import { getAuth, signOut } from "firebase/auth";
import ChatGPT from './ChatGPT.js';
import ImageGen from './ImageGen.js';
import { useNavigate } from 'react-router-dom';
import PromptsPlayground from './PromptsPlayground.js';
import Mailer from './Mailer.js';
import { doc, getDoc } from 'firebase/firestore';
import { sendwellDB, sendwellAuth } from './FirebaseInitSendwell';
import { mikeDB, mikeAuth } from './FirebaseInitMike';



function Home({uid, activeTab, setActiveTab, serverURL, roboURL, roboLoginData, sendwellOrMike}) {
    const [userAccessLevel, setUserAccessLevel] = useState('')

  const db = sendwellOrMike == 'sendwell' ? sendwellDB : mikeDB;
  const auth = sendwellOrMike === 'sendwell' ? sendwellAuth : mikeAuth;


    async function logOff () {
            signOut(auth).then(() => {
            // Sign-out successful.
            }).catch((error) => {
            // An error happened.
            });
    }

    
    const navigate = useNavigate();
 

    useEffect(() => {
        
        if (activeTab == 1) {
          navigate('/Stats');
        } else if (activeTab == 1) {
          navigate('/GenerateLinks');
        } else if (activeTab == 2) {
          navigate('/GenerateImage');
        } else if (activeTab == 3) {
          navigate('/GenerateCreative');
        } else if (activeTab == 4) {
          navigate('/Prompts');
        } else if (activeTab == 5) {
          navigate('/Mailer');
        } 
      }, [activeTab])


console.log(uid)

      async function getUserAccessLevel () {
        const userRef = doc(db, "users", uid);
        const userSnap = await getDoc(userRef);
        setUserAccessLevel(userSnap.data().type)
      }

      useEffect(() => {
        getUserAccessLevel()
      }, [uid])


      


    return ( 
        <div className='HomeContainer'>
            <div className='HomeNavBarMain'>
                <span></span>
                <div className='HomeNavBar'>
                    <span className={activeTab == 0 ? 'HomeNavBarTabActive' : 'HomeNavBarTab'} onClick={() => setActiveTab(0)}>Analytics</span>
                    {/*
                    <span className={activeTab == 1 ? 'HomeNavBarTabActive' : 'HomeNavBarTab'} onClick={() => setActiveTab(1)}>Generate Links</span>
                    */}
                    <span className={activeTab == 2 ? 'HomeNavBarTabActive' : 'HomeNavBarTab'} onClick={() => setActiveTab(2)}>Image Creation</span>
                    <span className={activeTab == 3 ? 'HomeNavBarTabActive' : 'HomeNavBarTab'} onClick={() => setActiveTab(3)}>AI Creatives</span>
                    <span className={activeTab == 4 ? 'HomeNavBarTabActive' : 'HomeNavBarTab'} onClick={() => setActiveTab(4)}>Prompts</span>
                    {userAccessLevel != 'creator' && <span className={activeTab == 5 ? 'HomeNavBarTabActive' : 'HomeNavBarTab'} onClick={() => setActiveTab(5)}>Mailer</span>}

                </div>
                <span onClick={() => logOff()} className='HomeNavBarTab HomeNavBarTabSignOutBtn'>Sign Out</span>
            </div>


            {activeTab == 0 ? <Stats uid={uid}/> : "" }
            {activeTab == 1 ? <Links/> : "" }
            {activeTab == 2 ? <ImageGen/> : "" }
            {activeTab == 3 ? <ChatGPT uid={uid} activeTab={activeTab} setActiveTab={setActiveTab} serverURL={serverURL} roboURL={roboURL} roboLoginData={roboLoginData} sendwellOrMike={sendwellOrMike}/> : "" }
            {activeTab == 4 ? <PromptsPlayground uid={uid} activeTab={activeTab} setActiveTab={setActiveTab} serverURL={serverURL} roboURL={roboURL} roboLoginData={roboLoginData} sendwellOrMike={sendwellOrMike}/> : "" }
            {activeTab == 5 ? <Mailer uid={uid} activeTab={activeTab} setActiveTab={setActiveTab} serverURL={serverURL} roboURL={roboURL} roboLoginData={roboLoginData} sendwellOrMike={sendwellOrMike}/> : "" }
        </div>
     );
}

export default Home;