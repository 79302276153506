import { initializeApp, getApps, getApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

// Firebase configuration for Sendwell
const firebaseConfigSendwell = {
  apiKey: "AIzaSyC_ahs4TQO82J7jKErdLPrgSbS-DNDrD_M",
  authDomain: "sw-mailer.firebaseapp.com",
  projectId: "sw-mailer",
  storageBucket: "sw-mailer.appspot.com",
  messagingSenderId: "1047054763355",
  appId: "1:1047054763355:web:3c53660216a0fe9feb867e",
  measurementId: "G-Y7M9TLJWS2"
};

// Ensure Firebase is initialized only once
const firebaseApp = getApps().find(app => app.name === "sendwell") || initializeApp(firebaseConfigSendwell, "sendwell");

// Get Firestore and Auth instances associated with this specific app
const sendwellDB = getFirestore(firebaseApp);
const sendwellAuth = getAuth(firebaseApp);

export { sendwellDB, sendwellAuth };
