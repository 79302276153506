

import React from 'react';
import axios from 'axios';
import { useState, useEffect } from 'react';
import './NewCreativeFullAuto.css';
import { ClearRounded, KeyboardArrowDownRounded, SearchRounded, Settings } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import Switch from 'react-switch';


function NewCreativeStandard({setBatchName, batchName, selectedPromptRevisionIDs, handlePromptRemoval, setSelectedPromptID,
    filteredPrompts, searchPromptsText, setSearchPromptsText, handlePromptSelection, setSelectedAIModel, selectedAIModel,
    generateBatch, generateBatchLoading, setGenerateBatchLoading, setCreativity, creativity, setAutopilot, autopilot, setPromptChain, promptChain,
    setMJMLChangeCount, MJMLChangeCount, setMJMLRestructureCount, MJMLRestructureCount, setHTMLRestructureCount, HTMLRestructureCount,
    setHTMLMoreRobustCount, HTMLMoreRobustCount, setAutoApplyAutoFix, autoApplyAutoFix, newBatchHideSettings, setNewBatchHideSettings,
    setAutoInsertTemplatingVariables, autoInsertTemplatingVariables, autoInsertWeatherConvo, setAutoInsertWeatherConvo
}) {
    return ( 
        <div className='ChatGPTNewCreativeContainer'>
        <div className='ChatGPTNewCreativeContainerHeader'>
          <Settings style={{opacity: 0}}/>
          <span className='ChatGPTNewCreativeTitle'>New Batch In Standard</span>
          <div className='ChatGPTNewCreativeHeaderSettingsIcon' style={{opacity: 0}} onClick={() => {}}>
            <Settings/>
          </div>
        </div>

        <div className='ChatGPTNewCreativeContainerSubContainer'>

          <div className='ChatGPTNewCreativeRow' style={{marginLeft: 0}}>

            <div className='ChatGPTNewCreativeInputWrapper'>
              <span className='ChatGPTNewCreativeInputName'>Batch Name</span>
              <input className='ChatGPTNewCreativeInput' type='text' placeholder='Ex: Costco 1R1' onChange={(event) => {setBatchName(event.target.value)}} value={batchName}/>
            </div>

          </div>


          <span className='ChatGPTSavedPromptsRowTitle'>Selected Offers ({selectedPromptRevisionIDs.reduce((total, item) => total + item.count, 0)})</span>
          <div className='ChatGPTSavedPromptsRow'>
            {selectedPromptRevisionIDs.map((x) => { return ( 
              <div key={x?.id} className='ChatGPTNewCreativeSavePromptOptionButtonSelected'>
                <span className='ChatGPTNewCreativeSavePromptOptionButtonText' onClick={() => {handlePromptRemoval({id: x.id, promptName: x.promptName, promptID: x.promptID}); setSelectedPromptID(x.id) }}>{x.promptName} {x.count}</span>
              
              </div>
            )})}
          </div>


          <span className='ChatGPTSavedPromptsRowTitle'>Offers ({filteredPrompts?.length})</span>
          <div className='ChatGPTNewPromptSearchWrapper'>
            <span className='ChatGPTNewPromptSearchIcon'><SearchRounded/></span>
            <input className='ChatGPTNewPromptSearch' type='text' placeholder='Costco' value={searchPromptsText} onChange={(e) => {setSearchPromptsText(e.target.value)}}/>
            <span className='ChatGPTNewPromptSearchIcon' onClick={() => {setSearchPromptsText('')}}><ClearRounded/></span>
          </div> 
          <div className='ChatGPTSavedPromptsRow'>
            {filteredPrompts.map((x) => { return ( 
              <div key={x?.id} className='ChatGPTNewCreativeSavePromptOptionButton'>
                <span className='ChatGPTNewCreativeSavePromptOptionButtonText' onClick={() => {handlePromptSelection({id: x.revisions[((x.revisions).length) - 1], promptName: x.promptName, promptID: x.id}); setSelectedPromptID(x.id) }}>{x.promptName}</span>
              
              </div>
            )})}
          </div>

            <div className='ChatGPTNewBatchSettingsDropdownButtonRow' onClick={() => {setNewBatchHideSettings(!newBatchHideSettings)}}>
              <span>{newBatchHideSettings ? 'Show' : 'Hide'} batch settings</span>
              <span style={{transform: newBatchHideSettings ? '' : 'rotate(180deg)', transition: 'all 0.5s', display: 'block'}}><KeyboardArrowDownRounded/></span>
            </div>
            <div className={newBatchHideSettings ? 'ChatGPTNewBatchSettingsDropdownClosed' : 'ChatGPTNewBatchSettingsDropdownOpen'}>

              <div className='ChatGPTNewCreativeInputWrapper'>
                <span className='ChatGPTNewCreativeInputName'>Creativity</span>
                <select
                  className='ChatGPTNewCreativeInputDropdown'
                  value={creativity}
                  onChange={(e) => setCreativity(e.target.value)}
                >
                <option value='1.0'>Very High</option>
                <option value="0.8">High</option>
                <option value="0.7">Normal</option>
                <option value="0.5">Low</option>
                <option value="0.1">Very Low</option>
                </select>
              </div>


              <div className='ChatGPTNewCreativeInputWrapper'>
                <span className='ChatGPTNewCreativeInputName'>AI Model</span>
                <select
                  className='ChatGPTNewCreativeInputDropdown'
                  value={selectedAIModel}
                  onChange={(e) => setSelectedAIModel(e.target.value)}
                >
                  <option value='gpt-4.5-preview'>GPT 4.5 (Preview)</option>
                  <option value='o3-mini'>GPT o3 Mini (Always most updated)</option>
                  <option value='o1'>GPT o1 (Always most updated)</option>
                  <option value='o1-mini'>GPT o1 Mini (Always most updated)</option>
                  <option value='gpt-4o-mini'>GPT 4o Mini (Always most updated)</option>
                  <option value='gpt-4o'>GPT 4o (Always most updated)</option>
                  <option value='gpt-4o-2024-05-13'>GPT 4o-2024-05-13</option>
                  <option value='gpt-4-1106-preview'>GPT 4-Turbo V1 (1106) 128K/4K (Recommended)</option>
                  <option value='gpt-4-turbo-preview'>GPT 4-Turbo V2 (0125) 128K/4K</option>
                  <option value='gpt-4'>GPT 4 8K/8K</option>
                  <option value='gpt-3.5-turbo-16k'>GPT 3.5-Turbo 16K/16k</option>
                  <option value='gpt-3.5-turbo'>GPT 3.5-Turbo 16K/4K</option>
                </select>
              </div>

              <Tooltip title="Automatically run prompts consecutively to get you a finished product with 1 click" placement="bottom">
                <div className='ChatGPTNewCreativeRow'>
                  <span className='ChatGPTNewCreativeRowText'>Autopilot</span>
                  <Switch
                    onChange={(checked) => setAutopilot(checked)}
                    checked={autopilot}
                    offColor="#d9d9d9" onColor="#26cf5c" uncheckedIcon={false} checkedIcon={false}
                  />
                </div>
              </Tooltip>


              {autopilot ?
                <>

                  {/*
                    <Tooltip title="Run Autopilot with prompt chaining" placement="bottom">
                      <div className='ChatGPTNewCreativeRow'>
                        <span className='ChatGPTNewCreativeRowText'>Prompt Chaining</span>
                        <Switch
                          onChange={(checked) => setPromptChain(checked)}
                          checked={promptChain}
                          offColor="#d9d9d9" onColor="#26cf5c" uncheckedIcon={false} checkedIcon={false}
                        />
                      </div>
                    </Tooltip>
                  */}

                  <div className='ChatGPTNewCreativeInputWrapper'>
                    <span className='ChatGPTNewCreativeInputName'>How many times should MJML content be changed?</span>
                    <select
                      className='ChatGPTNewCreativeInputDropdown'
                      value={MJMLChangeCount}
                      onChange={(e) => setMJMLChangeCount(e.target.value)}
                    >
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value='5'>5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value='10'>10</option>
                    </select>
                  </div>

                  <div className='ChatGPTNewCreativeInputWrapper'>
                    <span className='ChatGPTNewCreativeInputName'>How many times should MJML be restructured?</span>
                    <select
                      className='ChatGPTNewCreativeInputDropdown'
                      value={MJMLRestructureCount}
                      onChange={(e) => setMJMLRestructureCount(e.target.value)}
                    >
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value='5'>5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value='10'>10</option>
                    </select>
                  </div>

                  <div className='ChatGPTNewCreativeInputWrapper'>
                    <span className='ChatGPTNewCreativeInputName'>How many times should HTML be restructured?</span>
                    <select
                      className='ChatGPTNewCreativeInputDropdown'
                      value={HTMLRestructureCount}
                      onChange={(e) => setHTMLRestructureCount(e.target.value)}
                    >
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value='5'>5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value='10'>10</option>
                    </select>
                  </div>

                  <div className='ChatGPTNewCreativeInputWrapper'>
                    <span className='ChatGPTNewCreativeInputName'>How many times should HTML be made more robust?</span>
                    <select
                      className='ChatGPTNewCreativeInputDropdown'
                      value={HTMLMoreRobustCount}
                      onChange={(e) => setHTMLMoreRobustCount(e.target.value)}
                    >
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value='5'>5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value='10'>10</option>
                    </select>
                  </div>


                  <Tooltip title='Automatically inserts templating variables, {{top}}, {{middle}}, and {{bottom}}, in last revision' placement="bottom">
                    <div className='ChatGPTNewCreativeRow'>
                      <span className='ChatGPTNewCreativeRowText'>Auto Insert Variables</span>
                      <Switch
                        onChange={(checked) => {setAutoInsertTemplatingVariables(checked); setAutoInsertWeatherConvo(false);}}
                        checked={autoInsertTemplatingVariables}
                        offColor="#d9d9d9" onColor="#26cf5c" uncheckedIcon={false} checkedIcon={false}
                      />
                    </div>
                  </Tooltip>


                  <Tooltip title='Automatically inserts templating variable {{bottom}}, in a single div tag in last revision' placement="bottom">
                    <div className='ChatGPTNewCreativeRow'>
                      <span className='ChatGPTNewCreativeRowText'>Auto Insert Convo Variables (bottom) </span>
                      <Switch
                        onChange={(checked) => {setAutoInsertWeatherConvo(checked); setAutoInsertTemplatingVariables(false);}}
                        checked={autoInsertWeatherConvo}
                        offColor="#d9d9d9" onColor="#26cf5c" uncheckedIcon={false} checkedIcon={false}
                      />
                    </div>
                  </Tooltip>


                  <Tooltip title='Automatically runs preset "find and replaces" and fills variables in last revision' placement="bottom">
                    <div className='ChatGPTNewCreativeRow'>
                      <span className='ChatGPTNewCreativeRowText'>Autofix</span>
                      <Switch
                        onChange={(checked) => setAutoApplyAutoFix(checked)}
                        checked={autoApplyAutoFix}
                        offColor="#d9d9d9" onColor="#26cf5c" uncheckedIcon={false} checkedIcon={false}
                      />
                    </div>
                  </Tooltip>
              </>


              :<></>  
              }

            </div>

          {generateBatchLoading ?
            <span className='ChatGPTGenerateButton'>Loading - Please Wait</span>
          :
          <>
            {selectedPromptRevisionIDs.length > 0 && batchName != '' ?
              <span className='ChatGPTGenerateButton' onClick={() => (generateBatch(false))}>Generate Batch</span>
            :
              <></>
            }
          </>
          }

        </div>
      </div>
     );
}

export default NewCreativeStandard;