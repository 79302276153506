import React, { useEffect, useState } from 'react';
import './Login.css'
import { getAuth, signInWithPopup, GoogleAuthProvider, signInWithEmailAndPassword, createUserWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore"; 
import { sendwellDB, sendwellAuth } from './FirebaseInitSendwell';
import { mikeDB, mikeAuth } from './FirebaseInitMike';



function Login({sendwellOrMike}) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [validEmail, setValidEmail] = useState(false)
    const [errMsg, setErrMsg] = useState('')
    
    const [active, setActive] = useState('left'); // 'left' for "Your space", 'right' for "Arrival guide"

    const db = sendwellOrMike == 'sendwell' ? sendwellDB : mikeDB;
    const auth = sendwellOrMike == 'sendwell' ? sendwellAuth : mikeAuth;




    useEffect(() => {
        test()
    }, [])

    async function test () {

    }

    const provider = new GoogleAuthProvider();


    async function createUser () {
        setErrMsg('')
        console.log(email)
        console.log(password)
        createUserWithEmailAndPassword(auth, email, password)
          .then(async (userCredential) => {
            // Signed up 
            const user = userCredential.user;
            console.log(user)
            // Add a new document with a generated id.
            const docRef = await setDoc(doc(db, "users", user.uid), {
                email: email,
                userCreated: new Date(),
                uid: user.uid,
                firstName: firstName,
                lastName: lastName,
                initials: firstName.charAt(0) + lastName.charAt(0),
                hexCode: getRandomHexCode()
            });
            console.log("Document written with ID: ", docRef.id);
            // ...
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            setErrMsg(errorMessage)
            console.log(errorMessage)
            // ..
          });
    }



    async function login () {
        setErrMsg('')
        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in 
            const user = userCredential.user;
            console.log(user)
            // ...
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            setErrMsg(errorMessage)
            console.log(errorMessage)
        });
    }

    
    

    function isValidEmail(email) {
        setEmail(email)
        if (email.endsWith('@sendwell.com')) {
            // Regular expression for a basic email validation
            const emailRegex = /^[A-Za-z0-9+_.-]+@(.+)$/;
        
            setValidEmail(emailRegex.test(String(email).toLowerCase()));
        } else {
            setValidEmail(false)
        }
    }


    function getRandomHexCode() {
        // Generate random R, G, and B values
        const r = Math.floor(Math.random() * 256);
        const g = Math.floor(Math.random() * 256);
        const b = Math.floor(Math.random() * 256);
      
        // Convert RGB to hexadecimal format
        const hexR = r.toString(16).padStart(2, '0'); // Ensure at least two digits
        const hexG = g.toString(16).padStart(2, '0');
        const hexB = b.toString(16).padStart(2, '0');
      
        // Concatenate the hexadecimal values
        const hexCode = `#${hexR}${hexG}${hexB}`;
      
        return hexCode;
      }

      console.log(validEmail)


    return ( 
        <div className='loginContainer'>
            <div className='loginContentContainer'>

                <span className='loginTitle'>Sendwell Automail</span>

                <div className="toggle-container">
                    <button
                        className={`toggle-button ${active === 'left' ? 'active' : ''}`}
                        onClick={() => setActive('left')}
                    >
                        Login
                    </button>
                    <button
                        className={`toggle-button ${active === 'right' ? 'active' : ''}`}
                        onClick={() => setActive('right')}
                    >
                        Register
                    </button>
                </div>

                <input 
                className={validEmail ? 'validEmailField' : 'emailField'} 
                onChange={(event) => {isValidEmail(event.target.value)}} 
                value={email}
                placeholder='Email'
                />
                <input 
                className='passwordField' 
                onChange={(event) => setPassword(event.target.value)} 
                value={password}
                placeholder='Password'
                type='password'
                />

                <span>{errMsg}</span>
                {active == 'left' ? 
                    <span
                    className='signInButton'
                    onClick={() => {login()}}
                    >
                        Sign In
                    </span>
                :
                    <>
                        <input 
                            className='emailField' 
                            onChange={(event) => setFirstName(event.target.value)} 
                            value={firstName}
                            placeholder='First Name'
                        />
                        <input 
                            className='emailField' 
                            onChange={(event) => setLastName(event.target.value)} 
                            value={lastName}
                            placeholder='Last Name'
                        />
                        {validEmail ?
                            <span
                            className='signInButton'
                            onClick={() => {createUser()}}
                            >
                                Sign Up
                            </span>
                        :
                        <span style={{marginTop: 20}}>Please enter a valid email ending in @sendwell.com</span>
                        }
                    </>
                }
            </div>
        </div>
    );
}

export default Login;