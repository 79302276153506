import React, { useState, useEffect, useRef } from 'react';
import './PromptsPlayground.css';
import { doc, onSnapshot, collection, query, where, getDocs, orderBy, deleteDoc, addDoc, updateDoc, getDoc, setDoc, limit, arrayUnion } from "firebase/firestore";
import { sendwellDB, sendwellAuth } from './FirebaseInitSendwell';
import { mikeDB, mikeAuth } from './FirebaseInitMike';
import { Tooltip } from '@mui/material';
import Switch from 'react-switch';
import { ArrowBack, Check, CheckCircle, ClearRounded, Close, Delete, ExpandMoreRounded, KeyboardArrowDownRounded, PlayArrow, Save, SearchRounded, Share, X } from '@mui/icons-material';
import ReactModal from 'react-modal';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';



function PromptsPlayground({uid, activeTab, setActiveTab, serverURL, sendwellOrMike}) {

  const [searchPromptsText, setSearchPromptsText] = useState('')
  const [allPrompts, setAllPrompts] = useState([]);
  const [filteredPrompts, setFilteredPrompts] = useState([]);
  const [prompt, setPrompt] = useState('')
  const [promptLevel, setPromptLevel] = useState('individual')
  const [selectedPromptID, setSelectedPromptID] = useState('')
  const [userType, setUserType] = useState('')
  const [allUsers, setAllUsers] = useState([]);
  const [allUsersObject, setAllUsersObject] = useState([]);
  const [promptRevisions, setPromptRevisions] = useState([])
  const [selectedPromptRevision, setSelectedPromptRevision] = useState([])
  const [revisions, setRevisions] = useState([])
  const [promptName, setPromptName] = useState('')
  const [promptCategory, setPromptCategory] = useState('')
  const [creatingNewPrompt, setCreatingNewPrompt] = useState(true)
  const [selectAIModel, setSelectAIModel] = useState(false)
  const [selectedAIModel, setSelectedAIModel] = useState('gpt-4o')
  const [selectedRevision, setSelectedRevision] = useState('')
  const [showSharingOptions, setShowSharingOptions] = useState(false)
  const [promptSharedWith, setPromptSharedWith] = useState([])
  const [selectedPromptLevel, setSelectedPromptLevel] = useState('')
  const [shareWithEveryone, setShareWithEveryone] = useState(false)

  const [selectedPromptBody, setSelectedPromptBody] = useState('')
  const [selectedPromptFrom, setSelectedPromptFrom] = useState('')
  const [selectedPromptSubject, setSelectedPromptSubject] = useState('')
  const [selectedPromptGenerationLanguage, setSelectedPromptGenerationLanguage] = useState('MJML')

  const [beforeSelectedPromptBody, setBeforeSelectedPromptBody] = useState('')
  const [beforeSelectedPromptFrom, setBeforeSelectedPromptFrom] = useState('')
  const [beforeSelectedPromptSubject, setBeforeSelectedPromptSubject] = useState('')
  const [beforeSelectedAIModel, setBeforeSelectedAIModel] = useState('')
  const [beforePromptName, setBeforePromptName] = useState('')
  const [beforePromptSharedWith, setBeforePromptSharedWith] = useState([])
  const [beforePromptCategory, setBeforePromptCategory] = useState('')
  const [beforeGenerationLanguage, setBeforeGenerationLanguage] = useState('')

  const [showFilterOptions, setShowFilterOptions] = useState(false)
  const [filterMine, setFilterMine] = useState(false)
  const [sortBy, setSortBy] = useState('mostRecent')

  const [creator, setCreator] = useState('')


  const db = sendwellOrMike == 'sendwell' ? sendwellDB : mikeDB;
  const auth = sendwellOrMike === 'sendwell' ? sendwellAuth : mikeAuth;



  async function getUserType () {
    const userRef = doc(db, "users", uid);
    const userSnap = await getDoc(userRef);
    setUserType(userSnap.data().type)
    if (userSnap.data().type == 'franchise') {
      setFilterMine(true)
    }
  }


  async function getAllUsers() {
    console.log('getting all users');
  
    const q = query(
      collection(db, "users"),
      orderBy("firstName", "asc"),
    );
  
    try {
      const querySnapshot = await getDocs(q);
  
      const allUsersObjectArray = [];
      const allUsersArray = [];
      querySnapshot.forEach((doc) => {
        allUsersObjectArray.push(doc.data())
        allUsersArray[doc.id] = {
          id: doc.id,
          firstName: doc.data().firstName,
          lastName: doc.data().lastName,
          initials: doc.data().initials,
          email: doc.data().email,
          uid: doc.data().uid,
          userCreatedAt: doc.data().userCreatedAt,
          hexCode: doc.data().hexCode,
        };
      });
      console.log(allUsersArray)
      setAllUsers(allUsersArray);
      setAllUsersObject(allUsersObjectArray)

    } catch (error) {
      console.error("Error getting documents: ", error);
    }
  }

      
  async function getAllPrompts() {
    console.log('getting all Prompts');

    const conditions = [
      where('level', '==', promptLevel )
    ];
    if (filterMine) {
        conditions.push(where('creatorUID', '==', uid));
    }
    if (sortBy == 'mostRecent') {
      conditions.push(orderBy('timestamp', 'desc'));
    }
    if (sortBy == 'alphabetical') {
      conditions.push(orderBy('promptName', 'asc'));
    }
    const q = query(collection(db, 'prompts'), ...conditions);


    try {
      const querySnapshot = await getDocs(q);
  
      const allPromptsArray = [];
      querySnapshot.forEach((doc) => {
        allPromptsArray.push({
          id: doc.id,
          promptName: doc.data().promptName,
          timestamp: doc.data().timestamp,
          lastUsed: doc.data().lastUsed,
          uid: doc.data().uid,
          level: doc.data().level,
          uses: doc.data().uses,
          revenue: doc.data().revenue,
          creator: doc.data().creator,
          creatorUID: doc.data().creatorUID,
          cpm: doc.data().cpm,
          revisions: doc.data().revisions,
        }); 
      });
  
      console.log(allPromptsArray);
      setAllPrompts(allPromptsArray || []);
      setFilteredPrompts(allPromptsArray)

    } catch (error) {
      console.error("Error getting documents: ", error);
    }
  }



  async function getSelectedPrompt() {
    console.log("Getting selected prompts")
    const docRef = doc(db, "prompts", selectedPromptID);
    const docSnap = await getDoc(docRef);
    setPromptRevisions(docSnap.data().revisions)
    setSelectedPromptRevision(docSnap.data().revisions[((docSnap.data().revisions).length) - 1])
    setPromptName(docSnap.data().promptName)
    setPromptSharedWith(docSnap.data().uid)
    setBeforePromptSharedWith(docSnap.data().uid)
    setBeforePromptName(docSnap.data().promptName);
    setSelectedPromptLevel(docSnap.data().level)
    setPromptCategory(docSnap.data()?.promptCategory || '')
    
    //GET ALL REVISIONS
    const q = query(collection(db, "promptContent"), where("promptID", "==", selectedPromptID), orderBy('timestamp', 'desc'));
    const revisionsArray = [];
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      revisionsArray.push({
        id: doc.id,
        promptName: doc.data().promptName,
        promptBody: doc.data().promptBody,
        promptFrom: doc.data().promptFrom,
        promptSubject: doc.data().promptSubject,
        timestamp: doc.data().timestamp,
        changed: doc.data().changed,
        uid: doc.data().uid,
        uses: doc.data().uses,
        revenue: doc.data().revenue,
        cpm: doc.data().cpm,
        generationLanguage: doc.data().generationLanguage,
        
      })
    });
    console.log(revisionsArray)
    setRevisions(revisionsArray)
    setSelectedRevision(revisionsArray[0].id)

  }

  async function getSelectedRevision () {
    try {
      const promptContentRef = doc(db, "promptContent", selectedRevision);
      const promptContentDoc = await getDoc(promptContentRef);
      setCreatingNewPrompt(false)

      setSelectedPromptBody(promptContentDoc.data().promptBody)
      setSelectedPromptFrom(promptContentDoc.data().promptFrom)
      setSelectedPromptSubject(promptContentDoc.data().promptSubject)
      setSelectedAIModel(promptContentDoc.data().selectedAIModel);
      setSelectedPromptGenerationLanguage(promptContentDoc.data().generationLanguage);
      if (promptContentDoc.data().selectedAIModel != '') {
        setSelectAIModel(true)
      } else {
        setSelectAIModel(false)
      }

      setBeforeSelectedPromptBody(promptContentDoc.data().promptBody);
      setBeforeSelectedPromptFrom(promptContentDoc.data().promptFrom);
      setBeforeSelectedPromptSubject(promptContentDoc.data().promptSubject);
      setBeforePromptCategory(promptContentDoc.data().promptCategory);
      setBeforeSelectedAIModel(promptContentDoc.data().selectedAIModel);
      setBeforeGenerationLanguage(promptContentDoc.data().generationLanguage);
    } catch (error) {
      console.log(error)
    }
  }





  useEffect(() => {
    if (selectedPromptID != '') {
      getSelectedPrompt()
    }
  }, [selectedPromptID])


  useEffect(() => {
    if (selectedRevision != '') {
      getSelectedRevision()
    }
  }, [selectedRevision])

  useEffect(() => {
    getAllUsers()
    getUserType()
  }, [])
  

  useEffect(() => {
    if (userType !== '') {
      getAllPrompts()
    }
  }, [promptLevel, filterMine, sortBy, userType])

      





  async function savePrompt (updatedUIDs) {





    if (creatingNewPrompt) {
      //CREATING A NEW PROMPT FROM SCRATCH

      const promptContentID = "pc_" + randomCharacters(20)
      const promptID = "p_" + randomCharacters(20)


      await setDoc(doc(db, "promptContent", promptContentID), {
        changed: ["Initial Version"],
        promptBody: selectedPromptBody,
        promptFrom: selectedPromptFrom,
        promptSubject: selectedPromptSubject,
        promptCategory: promptCategory,
        generationLanguage: selectedPromptGenerationLanguage,
        selectedAIModel: selectAIModel ? selectedAIModel : '',
        timestamp: new Date(),
        uid: uid,
        promptID: promptID,
        cpm: 0,
        revenue: 0,
        uses: 0,
      });
      console.log("Prompt Content written with ID: ", promptContentID);


      await setDoc(doc(db, "prompts", promptID), {
        cpm: 0,
        creator: allUsers[uid].firstName,
        lastUsed: new Date(),
        level: promptLevel,
        promptName: promptName,
        promptCategory: promptCategory,
        promptNameLowercase: promptName.toLowerCase(),
        revisions: [promptContentID],
        revenue: 0,
        timestamp: new Date(),
        uid: [uid],
        uses: 0,
        creatorUID: uid
      });
      console.log("Prompt written with ID: ", promptID);
      setCreatingNewPrompt(false)
      setSelectedPromptID(promptID)
      getAllPrompts()


    } else {
      //CREATING REVISION BASED ON EXISTING PROMPT

      const changes = [];
      if (beforePromptName !== promptName) { changes.push("Name"); }
      if (beforeGenerationLanguage !== selectedPromptGenerationLanguage) { changes.push("Generation Language"); }
      if (beforeSelectedAIModel !== (selectAIModel ? selectedAIModel : '')) { changes.push("AI Model"); }
      if (beforeSelectedPromptBody !== selectedPromptBody) { changes.push("Body"); }
      if (beforeSelectedPromptFrom !== selectedPromptFrom) { changes.push("From"); }
      if (beforeSelectedPromptSubject !== selectedPromptSubject) { changes.push("Subject"); }
      if (beforePromptCategory !== promptCategory) { changes.push("Category"); }

      if (beforePromptSharedWith.length === promptSharedWith.length && changes != []) {
        //SHARING WASN'T UPDATED
        const promptContentID = "pc_" + randomCharacters(20)

        await setDoc(doc(db, "promptContent", promptContentID), {
          changed: changes,
          promptBody: selectedPromptBody,
          promptFrom: selectedPromptFrom,
          promptSubject: selectedPromptSubject,
          generationLanguage: selectedPromptGenerationLanguage || 'MJML',
          promptCategory: promptCategory,
          selectedAIModel: selectAIModel ? selectedAIModel : '',
          timestamp: new Date(),
          uid: uid,
          promptID: selectedPromptID,
          cpm: 0,
          revenue: 0,
          uses: 0,
        });
        console.log("Prompt Content written with ID: ", promptContentID);
  
  
        await updateDoc(doc(db, "prompts", selectedPromptID), {
          creator: allUsers[uid].firstName,
          promptName: promptName,
          promptCategory: promptCategory,
          promptNameLowercase: promptName.toLowerCase(),
          revisions: arrayUnion(promptContentID),
          timestamp: new Date(),
          uid: updatedUIDs || promptSharedWith,
        });
        console.log("Prompt written with ID: ", selectedPromptID);

      } else {
        //SHARING WAS UPDATED
        console.log('SHARING WAS UPDATED')
        await updateDoc(doc(db, "prompts", selectedPromptID), {
          uid: updatedUIDs || promptSharedWith,
        });
      }

      setCreatingNewPrompt(false)
      getAllPrompts()
      getSelectedPrompt();
      
    }





    function randomCharacters(length) {
      const characters = 'abcdefghijklmnopqrstuvwxyz';
      let result = '';
      for (let i = 0; i < length; i++) {
          result += characters.charAt(Math.floor(Math.random() * characters.length));
      }
      return result; 
    }

  }



  async function adjustSharing(userID) {
    let updatedUIDs = [];

    console.log(allUsersObject)

    console.log(promptSharedWith)
  
    if (userID == 'all') {
      for (let i = 0; i < allUsersObject.length; i++) {
        console.log(allUsersObject[i].uid)
        updatedUIDs.push(allUsersObject[i].uid);
      }
    } else {
      if (promptSharedWith.includes(userID)) {
        updatedUIDs = promptSharedWith.filter(item => item !== userID);
      } else {
        updatedUIDs = [...promptSharedWith, userID];
      }
    }
    
  
    setPromptSharedWith(updatedUIDs);
  }
  

  async function deletePrompt() {
    //const updatedUIDs = promptSharedWith.filter(item => item !== uid);
    //setPromptSharedWith(updatedUIDs);
    //savePrompt(updatedUIDs);
    await deleteDoc(doc(db, "prompts", selectedPromptID))
    getAllPrompts()
  }





  useEffect(() => {
    // Regular expression to match characters you want to disallow
    const regex = /[&']/g;

    // Check if the input contains disallowed characters
    if (regex.test(promptName)) {
        // If a disallowed character is found, remove it from the input value
        const sanitizedValue = promptName.replace(regex, '');
        // Update the state with the sanitized value
        setPromptName(sanitizedValue);
    }
  }, [promptName]);







  useEffect(() => {
    const filteredPromptsArray = allPrompts.filter(prompt =>
      prompt.promptName.toLowerCase().includes(searchPromptsText.toLowerCase())
    );
    setFilteredPrompts(filteredPromptsArray);
  }, [searchPromptsText]);
  

























    return ( 
      <div className='PlaygroundContainer'>
        <div className='PlaygroundLeftContainer'>
          <span className='PlaygroundLeftCreatePromptButton' 
            onClick={() => {
              setCreatingNewPrompt(true);
              setSelectedPromptBody('')
              setSelectedPromptFrom('')
              setSelectedPromptSubject('')
              setPromptName('')
              setRevisions([])
              setSelectedPromptID('')
            }}
          >
            Create Prompt
          </span>

          <div className='PlaygroundLeftToggleContainer'>
            <div className="toggle-container">
              {userType == 'admin' && (
                <button
                    className={`toggle-button ${promptLevel === 'master' ? 'active' : ''}`}
                    onClick={() => {setPromptLevel('master'); setAllPrompts([])}}
                >
                    Masters
                </button>
              )}
              {userType == 'admin' && (
                <button
                    className={`toggle-button ${promptLevel === 'templating' ? 'active' : ''}`}
                    onClick={() => {setPromptLevel('templating'); setAllPrompts([])}}
                >
                    Templating
                </button>
              )}
              <button
                  className={`toggle-button ${promptLevel === 'individual' ? 'active' : ''}`}
                  onClick={() => {setPromptLevel('individual'); setAllPrompts([])}}
              >
                  Offers
              </button>
            </div>
          </div>

          
          {userType == 'admin' && (
            <>
              <div className='PlaygroundExpandFilterOptionsContainer' onClick={() => {setShowFilterOptions(!showFilterOptions)}}>
                <span className='PlaygroundExpandFilterOptionsTitle'>Filter Options</span>
                <span className={showFilterOptions ? 'PlaygroundExpandFilterOptionsArrowRotated' : 'PlaygroundExpandFilterOptionsArrow'}><ExpandMoreRounded/></span>
              </div>

              <div className='PlaygroundFilterOptionsContainer' style={{maxHeight: showFilterOptions ? 300 : 0, opacity: showFilterOptions ? "100" : "0", marginTop: showFilterOptions ? 20 : 0, marginBottom: showFilterOptions ? 20 : 0}}>

                <div className='PlaygroundAIModelSelectWrapper' style={{width: '95%', marginLeft: 20}}>
                  <span className='PlaygroundAIModelSelectLabel'>Sort By</span>
                  <select
                    className='PlaygroundAIModelSelect'
                    value={sortBy}
                    onChange={(e) => setSortBy(e.target.value)}
                  >
                    <option value="mostRecent">Most Recent</option>
                    <option value="alphabetical">Alphabetical</option>
                  </select>
                </div>


                <div className='PlaygroundFilterOptionsSwitchRow'>
                  <Switch
                    onChange={(checked) => setFilterMine(checked)}
                    checked={filterMine}
                    offColor="#d9d9d9" onColor="#26cf5c" uncheckedIcon={false} checkedIcon={false}
                  />
                  <span className='PlaygroundFilterOptionsSwitchLabel'>Only Show Mine</span>
                </div>
              </div>
            </>
          )}

          <div className='ChatGPTNewPromptSearchWrapper' style={{marginLeft: 15, marginRight: 15}}>
            <span className='ChatGPTNewPromptSearchIcon'><SearchRounded/></span>
            <input className='ChatGPTNewPromptSearch' type='text' placeholder='Costco' value={searchPromptsText} onChange={(e) => {setSearchPromptsText(e.target.value)}}/>
            <span className='ChatGPTNewPromptSearchIcon' onClick={() => {setSearchPromptsText('')}}><ClearRounded/></span>
          </div> 

          <span style={{paddingRight: 20, marginBottom: 20, display: 'block', textAlign: 'right'}}>{allPrompts?.length} Results</span>

          {filteredPrompts.map((x) => { return (
            <div 
              key={x.id} 
              className='PlaygroundLeftPromptContainer' 
              style={{border: selectedPromptID == x.id ? '1px solid #000' : '1px solid #e7e7e7'}} 
              onClick={() => {
                setSelectedPromptID(x.id);               
                setSelectedPromptBody('')
                setSelectedPromptFrom('')
                setSelectedPromptSubject('')
              }}
            >
              <span className='PlaygroundLeftPromptName'>{x.promptName}</span>
              <span className='PlaygroundLeftPromptTimeStamp'>Last used on {x.lastUsed.toDate().toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric',})}</span>
              {x.level == 'inidividual' ? <span className='PlaygroundLeftPromptLevel'>Made by {x.creator}</span> : ''}
              <span className='PlaygroundLeftPromptUses'>
                {x.uses} Uses 
                &bull; {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(x.revenue)} Revenue 
                &bull; {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(x.cpm)} CPM 
              </span>

              {promptLevel == 'master' ?
                <Tooltip title={(x?.creator?.length) + (x?.creator?.length == 1 ? " user" : " users")} placement='top'>
                  <div className='PlaygroundLeftPromptUsers'>
                    
                      <Tooltip title={allUsers[x?.creatorUID]?.firstName + " " + allUsers[x?.creatorUID]?.lastName} placement='bottom'>
                        <div
                          style={{
                            position: 'absolute',
                            backgroundColor: allUsers[x?.creatorUID]?.hexCode,
                            color: 'white',
                            fontWeight: 600,
                            fontSize: 14,
                            width: 30,
                            height: 30,
                            borderRadius: 40,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          {allUsers[x?.creatorUID]?.initials} 
                        </div>
                      </Tooltip>
                   
                  </div>
                </Tooltip>
                :<></>}

            </div>
          )})}

        </div>



        <div className='PlaygroundRightContainer'>


          <div className='PlaygroundRightContentContainer'>

            <div className='PlaygroundTitleBar'>

              <div className='PlaygroundTitleBarLeft' onClick={() => {setActiveTab(3)}}>
                <ArrowBack/>
              </div>

              {promptName !== '' ?
                <span className='PlaygroundContentTitle'>Creating Revision for <span style={{fontWeight: 800, fontSize: 18}}>{promptName}</span></span>
              :
                <span className='PlaygroundContentTitle'>Creating New Prompt</span>
              }

              <div className='PlaygroundTitleBarRight'>
                <Tooltip title="Run" style={{display: 'none'}}>      
                  <div className='PlaygroundTitleBarRightIcon'>
                    <PlayArrow/>
                  </div>
                </Tooltip>

                <Tooltip title="Share">      
                  <div className='PlaygroundTitleBarRightIcon' onClick={() => {setShowSharingOptions(true)}}>
                    <Share/>
                  </div>     
                </Tooltip> 

                <Tooltip title="Save">      
                  <div className='PlaygroundTitleBarRightIcon' onClick={() => {savePrompt()}}>
                    <Save 
                      style={{
                        color: 
                          (beforePromptName !== promptName ||
                          beforeSelectedAIModel !== (selectAIModel ? selectedAIModel : '') ||
                          beforeSelectedPromptBody !== selectedPromptBody ||
                          beforeSelectedPromptFrom !== selectedPromptFrom ||
                          beforeSelectedPromptSubject !== selectedPromptSubject ||
                          beforePromptSharedWith !== promptSharedWith) &&
                          creatingNewPrompt != true ?
                          "#444" : "#bbb"
                      }}
                    />
                  </div>
                </Tooltip>    

                {selectedPromptLevel == 'individual' ?
                  <Tooltip title="Remove Prompt">      
                    <div className='PlaygroundTitleBarRightIcon' onClick={() => {deletePrompt()}}>
                      <Delete/>
                    </div>
                  </Tooltip>
                :
                  <></>
                }
              </div>
            </div>








            {promptLevel == 'individual' && (
              <Tooltip title="Select the language you want the prompt to generate in" placement='bottom'>      
                <div className='PlaygroundLeftToggleContainer'>
                  <div className="toggle-container">
                      <button
                          className={`toggle-button ${selectedPromptGenerationLanguage === 'MJML' ? 'active' : ''}`}
                          onClick={() => {setSelectedPromptGenerationLanguage('MJML');}}
                      >
                          MJML
                      </button>
                      <button
                          className={`toggle-button ${selectedPromptGenerationLanguage === 'HTML' ? 'active' : ''}`}
                          onClick={() => {setSelectedPromptGenerationLanguage('HTML');}}
                      >
                          HTML
                      </button>
                    </div>
                  </div>
                </Tooltip>
              )}



            <div className='PlaygroundInputWrapper'>
              <span className='PlaygroundTextareaLabel'>Prompt Name</span>
              <input className='PlaygroundInput' value={promptName} onChange={(e) => {setPromptName(e.target.value)}} placeholder='Enter your prompt name'/>
            </div>


            {/* 
            <select       
                value={creator}
                onChange={(e) => setCreator(e.target.value)}
            >
              {allUsersObject.map((x) => { return (
                <option value={x.uid}>{x.firstName}</option>
              )})}
            </select>
            <span>{creator}</span> \
            */}



            {promptLevel == 'individual' ?
              <div className='PlaygroundFromSubRow'>
                <div className='PlaygroundTextareaWrapper' style={{width: 362}}>
                  <span className='PlaygroundTextareaLabel'>From Example</span>
                  <textarea className='PlaygroundTextarea' style={{height: 60}} value={selectedPromptFrom} onChange={(e) => {setSelectedPromptFrom(e.target.value)}} placeholder='Enter your from example'/>
                </div>

                <div style={{minWidth: 100}}></div>

                <div className='PlaygroundTextareaWrapper' style={{width: 362}}>
                  <span className='PlaygroundTextareaLabel'>Subject Example</span>
                  <textarea className='PlaygroundTextarea' style={{height: 60}} value={selectedPromptSubject} onChange={(e) => {setSelectedPromptSubject(e.target.value)}} placeholder='Enter your subject example'/>
                </div>
              </div>
            :<></>}

            
            
            
            
            {promptLevel == 'templating' &&
              <div className='PlaygroundTextareaWrapper'>
                <span className='PlaygroundTextareaLabel'>Category</span>
                {/* <textarea className='PlaygroundTextarea' style={{height: 60}} value={promptCategory} onChange={(e) => {setPromptCategory(e.target.value)}} placeholder='Enter your prompt category'/> */}
                <select value={promptCategory} onChange={(e) => {setPromptCategory(e.target.value)}} style={{width: '100%', height: 40, paddingLeft: 10, borderRadius: 10}}> 
                  <option value="top">Top</option>
                  <option value="middle">Middle</option>
                  <option value="bottom">Bottom</option>
                </select>
              </div>
            }


            <div className='PlaygroundTextareaWrapper'>
              <span className='PlaygroundTextareaLabel'>Body Prompt</span>
              <textarea className='PlaygroundTextarea' value={selectedPromptBody} onChange={(e) => {setSelectedPromptBody(e.target.value)}} placeholder='Enter your body prompt'/>
            </div>


            <div className='PlaygroundDefaultAIModelRow'>

              <div className='PlaygroundDefaultAIModelRowLeft'>

                <Switch
                  onChange={(checked) => setSelectAIModel(checked)}
                  checked={selectAIModel}
                  offColor="#d9d9d9" onColor="#26cf5c" uncheckedIcon={false} checkedIcon={false}
                />
                <span style={{paddingLeft: 10}}>Set override AI model</span>
              </div>

              {selectAIModel ?
                <div className='PlaygroundAIModelSelectWrapper'>
                  <span className='PlaygroundAIModelSelectLabel'>AI Model</span>
                  <select
                    className='PlaygroundAIModelSelect'
                    value={selectedAIModel}
                    onChange={(e) => setSelectedAIModel(e.target.value)}
                  >
                    <option value='gpt-4.5-preview'>GPT 4.5 (Preview)</option>
                    <option value='o3-mini'>GPT o3 Mini (Always most updated)</option>
                    <option value='o1'>GPT o1 (Always most updated)</option>
                    <option value='o1-mini'>GPT o1 Mini (Always most updated)</option>
                    <option value='gpt-4o'>GPT 4o (Always most updated)</option>
                    <option value='gpt-4o-2024-05-13'>GPT 4o-2024-05-13</option>
                    <option value='gpt-4-1106-preview'>GPT 4-Turbo V1 (1106) 128K/4K (Recommended)</option>
                    <option value='gpt-4-turbo-preview'>GPT 4-Turbo V2 (0125) 128K/4K</option>
                    <option value='gpt-4'>GPT 4 8K/8K</option>
                    <option value='gpt-3.5-turbo-16k'>GPT 3.5-Turbo 16K/16k</option>
                    <option value='gpt-3.5-turbo'>GPT 3.5-Turbo 16K/4K</option>
                  </select>
                </div>
              : <span style={{display: 'block'}}></span>
              }


            </div>





            

     

          </div>














          <div className='PlaygroundRightRevisionsContainer'>
            <span className='PlaygroundRightTitle'>Revisions</span>
            {revisions.map((x) => { return (
              <div className='PlaygroundRevision' onClick={() => {setSelectedRevision(x.id); setCreatingNewPrompt(false)}}  style={{border: selectedRevision == x.id ? '1px solid #000' : '1px solid #e7e7e7'}}>
                <span className='PlaygroundRevisionName'>{x.timestamp.toDate().toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric',})}</span>
                <span className='PlaygroundRevisionChanges'>
                  Changed: 
                  {x?.changed?.map((y) => { return (
                    <span> {y} </span>
                    
                  )})}
                </span>
                <span className='PlaygroundRevisionChanges'>
                  {x.uses} Uses 
                  &bull; {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(x.revenue)} Revenue 
                  &bull; {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(x.cpm)} CPM 
                </span>

                <div className='PlaygroundRightPromptUsers'>
                  <div style={{backgroundColor: allUsers[x.uid]?.hexCode, color: 'white', fontWeight: 600, fontSize: 14, width: 30, height: 30, borderRadius: 40, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginRight: 10}}>
                    {allUsers[x.uid]?.initials} 
                  </div>
                </div>

              </div>
            )})}
          </div>









        </div>



        <ReactModal className='PlaygroundSharingContainer' isOpen={showSharingOptions} onRequestClose={() => {setShowSharingOptions(false)}} ariaHideApp={false}>
          
          <div className='PlaygroundSharingContainerHeader'>
            <div className='PlaygroundSharingContainerHeaderIcon' onClick={() => {setShowSharingOptions(false)}}>
              <Close/>
            </div>
            <span className='PlaygroundSharingContainerHeaderText'>Share with</span>
            <div className='PlaygroundSharingContainerHeaderIcon' onClick={() => {savePrompt(); setShowSharingOptions(false)}}>
              <Save/>
            </div>
          </div>

          <div>
            <div className={promptSharedWith.length == allUsersObject.length ? 'PlaygroundSharingUserRowSelected' : 'PlaygroundSharingUserRow'} onClick={() => {adjustSharing('all')}}>
              <div className='PlaygroundSharingUserRowAvatar' style={{opacity: 0}}>AA</div>
              <span className='PlaygroundSharingUserName'>Share with everyone</span>
              <span>{promptSharedWith.length == allUsersObject.length ? <CheckCircle style={{color: '#29ae04', fontSize: 30}}/> :  <Check style={{color: '#ffffff00', fontSize: 30 }}/>}</span>
            </div>
            {allUsersObject?.map((x, index) => { return (
              <div key={index} className={promptSharedWith?.includes(x.uid) ? 'PlaygroundSharingUserRowSelected' : 'PlaygroundSharingUserRow'} onClick={() => {adjustSharing(x.uid)}}>
                <div className='PlaygroundSharingUserRowAvatar' style={{backgroundColor: x.hexCode}}>
                  {x.initials} 
                </div>
                <span className='PlaygroundSharingUserName' >{x.firstName + " " + x.lastName}</span>
                <span>{promptSharedWith?.includes(x.uid) ? <CheckCircle style={{color: '#29ae04', fontSize: 30}}/> :  <Check style={{color: '#ffffff00', fontSize: 30 }}/>}</span>
              </div>
            )})}
          </div>

          

          </ReactModal>



      </div>
     );
}

export default PromptsPlayground;